<template>
    <div class="html">
        <!-- top -->
        <div class="head d-flex j-sb a-center">
            <div class="logo d-flex a-center" style="font-size:18px;color:#666"><h3 style="margin-right:10px;font-size: 20px;letter-spacing: 2px;">{{ $win.mconfig.nav_title || $win.mconfig.name }}</h3></div>
            <div class="navbar">
                <div class="user d-flex a-center">
                    
                    <el-dropdown @command="handleClick">
                        <span class="el-dropdown-link d-flex a-center">
                            <el-avatar style="margin-right:10px;" size="medium" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
                            {{$store.state.user.username}}
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="logout"><i class=""></i> 退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
        <div class="body">
            <Menu class="menu"/>
            <div class="main">
                <!-- cont -->
                <div class="cont" style="top: 5px;">
                    <!-- <overlay-scrollbars style="width: 100%;height: 100%;" class="el-fade-in">\
                        <router-view v-slot="{ Component }">
                            <transition name="slide-fade">
                                <component :is="Component" /> 
                            </transition>
                        </router-view>
                    </overlay-scrollbars> -->
                    <overlay-scrollbars style="width: 100%;height: 100%;" class="el-fade-in">
                        <div class="d-flex" style="position: absolute;left: 0;top: 0;width: 100%;height: 100%;">
                            <div class="flex-1 position-relative">
                                <router-view v-slot="{ Component }">
                                    <transition name="slide-fade">
                                        <component :is="Component" /> 
                                    </transition>
                                </router-view>
                            </div>
                            

                        </div>
                    </overlay-scrollbars>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Menu from '@/components/Menu.vue'
import {mapMutations} from 'vuex'
export default {
    components:{
        Menu
    },
    data(){
        return {
        }
    },
    mounted(){
    },
    methods:{
        ...mapMutations(['logout']),
        handleClick(command){
            if(command=='logout') {
                //退登
                this.logout()
                this.$router.push('/login')
            }
        }
    }
}
</script>

<style scoped lang="less">
    @top_px:60px; //顶部高度
    
    @bg_color:#f5f7f9; //背景颜色
    @top_bg-color:#ffffff; //顶部导航背景颜色
    @sider_w:200px; //侧边导航宽度
    @sider_bg-color:#191A23; //侧边导航背景颜色

    /deep/ .el-menu {
        background-color: @sider_bg-color;
    }
    /deep/ .el-menu-item:focus,
    /deep/ .el-menu-item:hover,
    /deep/ .el-submenu__title:focus,
    /deep/ .el-submenu__title:hover {
        background-color: #101117!important;
        color:#ffffff!important;
    }

    /deep/ .el-menu-item.is-active {
        background-color: #2d8cf0!important;
    }
    /deep/ .el-submenu.is-opened {
        background-color: #101117!important;
    }

    /deep/ .el-submenu.is-opened .el-menu {
        background-color: #101117!important;
    }

    /deep/ .el-pagination.is-background .el-pager li {
        height: 32px;
        width: 32px;
        border: 1px solid #dcdee2;
        border-radius: 4px;
        background-color:transparent;
        font-weight: 400;
    }

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: transparent;
        border-color: #2d8cf0;
        color: #2d8cf0;
    }

    /deep/ .el-pagination.is-background .btn-prev, 
    /deep/ .el-pagination.is-background .btn-next {
        background-color: transparent;
        width: 32px;
        height: 32px;
        border: 1px solid #dcdee2;
        border-radius: 4px;
        font-weight: 400;
    }

    /deep/ .el-pagination .el-select .el-input {
        width: 80px;
    }

    /deep/ .el-input--mini .el-input__inner {
        height: 32px;
        border-radius: 4px;
    }


    // el-upload
    /deep/ .el-upload {
        display: block;
    }
    /deep/ .el-upload-dragger {
        width: 100%;
    }

    // transition 过渡
    .slide-fade-enter-active {
        transition: all .8s ease;
    }
    // .fade-leave-active {
    //     transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    // }
    .slide-fade-enter, .slide-fade-leave-to
    {
        transform: translateX(50px);
        opacity: 0;
    }


    .html{
        position:fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        background-color: @bg_color;
    }

    .head {
        height: @top_px;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        padding: 0 15px;
        background-color: @top_bg-color;
    }

    .body {
        position:fixed;
        top:@top_px;
        left: 0;
        right: 0;
        bottom: 0;
    
        .main {
            position:absolute;
            left: @sider_w;
            top: 0;
            right: 0;
            bottom: 0;
            .tabs_nav {
                position:absolute;
                left: 0;
                right: 0;
                top: 0;
                height: 32px;
                padding: 10px;
            }
            .cont {
                position:absolute;
                top: 15px;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
    }
</style>
